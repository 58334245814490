<template>
  <Collections
    :is-store-product-preload="isStoreProductPreload"
    :props-data-for-searching="propsDataForSearching"
    :sections-order="sectionsOrder"
    :filtered-list-of-collections="filteredListOfCollections"
    use-section-settings
    @changeReorder="$emit('changeReorder', $event)"
    @changeExpandSection="$emit('changeExpandSection', $event)">
    <template #topDetails="{actionResort}">
      <AppSort
        wrapper-class="col-12 px-0"
        :default-item="defaultItem"
        :sort-items="sortItems"
        @resort="actionResort" />
    </template>
    <template #createButtonActions="{ searchProductsMode }">
      <div
        class="buttons-container">
        <CollectionCreateOrUpdate
          v-if="!searchProductsMode"
          v-on="$listeners" />
        <CollectionsCreateProductDialog
          :prop-library-id="currentLibraryId"
          :is-store-product-preload="isStoreProductPreload"
          v-on="$listeners" />
      </div>
    </template>
  </Collections>
</template>
<script>
import AppSort from '@/components/App/AppSort';
import Collections from '@/components/Collections';
import CollectionCreateOrUpdate from '@/components/CollectionsLibrarysRelocate';
import CollectionsCreateProductDialog from '@/components/Collections/CollectionsCreateProductDialog';
import propsMyCollections from '@/mixins/propsMyCollections';
export default {
  name: 'CollectionsWrapper',
  components: {
    AppSort,
    Collections,
    CollectionCreateOrUpdate,
    CollectionsCreateProductDialog,
  },
  mixins: [propsMyCollections],
  props: {
    sectionsOrder: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
<style scoped>
</style>
