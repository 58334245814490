
<template>
  <div class="collections">
    <div class="secondary-blocks">
      <ListingImageItem
        :libraries-list="updatedCollectionsList"
        :show-collapsed-items="showCollapsedItems"
        :collapsed-items-amount="collapsedItemsAmount"
        :intersect="intersect"
        :is-fetching="isLoading"
        :show-counter="showCounter"
        :show-unread-comments="group == WORKSPACE || group == GROUP_SHARED"
        :counter="{
          prop: 'productsCount',
          type: 'products',
        }"
        v-on="$listeners"
        @navigate="navigateNext($event, group, isSharedPage)">
        <template
          v-for="(_, name) in $scopedSlots"
          v-slot:[name]="props">
          <slot
            :name="name"
            v-bind="props" />
        </template>
        <template #actions="{ item }">
          <v-icon
            v-if="!hideActions && !isSharedPage"
            class="brightness-icon"
            color="lightGrey"
            medium
            @click.stop="$emit('click-action-menu', {
              item,
              event: $event,
            })"
            v-on="$listeners">
            mdi-dots-vertical
          </v-icon>
        </template>
      </ListingImageItem>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { debounce } from 'lodash';

import { GROUP_SHARED } from '@/constants';
import {
  WORKSPACE, WS_PAGE,
} from '@/constants/cores';

import ListingImageItem from '../ListingImageItem';

import NavigateToCollection from '@/mixins/NavigateToCollection';

export default {
  name: 'CollectionsListing',
  components: {
    ListingImageItem,
  },
  mixins: [NavigateToCollection],
  props: {
    collectionsList: {
      type: Array,
      default: () => [],
    },
    intersect: {
      type: Boolean,
      default: () => false,
    },
    hideActions: {
      type: Boolean,
      default: false,
    },
    group: {
      type: String,
      default: null,
    },
    showCollapsedItems: {
      type: Boolean,
      default: false,
    },
    collapsedItemsAmount: {
      type: Number,
      default: 4,
    },
    isSharedPage: {
      type: Boolean,
      default: false,
    },
    showCounter: {
      type: Boolean,
      default: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      WORKSPACE,
      GROUP_SHARED,
      WS_PAGE,
      updatedCollectionsList: [],
    };
  },
  computed: {
    ...mapGetters('FeatureFlags', ['createAnchorForCollections']),
  },
  watch: {
    collectionsList(newVal, oldVal) {
      if (!this.lodash.isEqual(newVal, oldVal)) {
        this.addRoute.cancel();
        this.addRoute(newVal);
      }
    },
  },
  created() {
    this.addRoute = debounce(async (collections) => {
      // if feature is not enabled then do not make computations
      if (!this.createAnchorForCollections) {
        this.updatedCollectionsList = collections;
        return;
      }
      const updatedLib = await Promise.all(
        collections.map(async (item) => {
          const route = await this.returnNavigateNext(item, this.group, this.isSharedPage);
          return {
            ...item,
            route,
          };
        })
      );
      this.updatedCollectionsList = updatedLib;
    }, 1);

    this.addRoute(this.collectionsList);
  },
};
</script>
<style lang="scss">
  .collections {
    display: grid;
    gap: 15px;
  }
</style>
