<!-- This is shown when the component is still loading for the initial render -->
<template>
  <div class="library-page-container">
    <CollectionSearchSkeleton v-if="showSearch" />
    <v-skeleton-loader
      class="d-flex justify-end justify-sm-space-between flex-column flex-sm-row w-100 mt-6 "
      type="button@2" />
    <div class="library-page-container">
      <CollectionListingSectionSkeleton />
    </div>
  </div>
</template>

<script>
import CollectionSearchSkeleton from '@/components/App/AppSkeleton/Collections/CollectionSearchSkeleton';
import CollectionListingSectionSkeleton from '@/components/App/AppSkeleton/Collections/CollectionListingSectionSkeleton';
export default {
  name: 'CollectionBaseSkeleton',
  components: {
    CollectionSearchSkeleton,
    CollectionListingSectionSkeleton,
  },
  props: {
    showSearch: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style scoped lang="scss">
  ::v-deep .v-skeleton-loader.v-skeleton-loader--is-loading {
    .v-skeleton-loader__button {
      margin-top: 12px;
      width: 160px;
      height: 28px;
      align-self: end;
    }
  }
</style>