var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._t("appTabs"),(!_vm.isSharedPage)?_c('div',{staticClass:"px-9 d-flex justify-space-between"},[_c('app-breadcrumbs',{attrs:{"push-back-url":_vm.getBreadcrumbsList.pushBackUrl,"breadcrumbs-list":_vm.getBreadcrumbsList.breadcrumbs}}),_c('app-switcher-to-listing')],1):_vm._e(),(_vm.isWorkspacePage)?_c('workspace-page-header',{attrs:{"hide-follow":_vm.isSharedPage,"workspace-page":_vm.workspacePage}}):_vm._e(),(!_vm.activeHeader)?_c('CollectionsBaseSkeleton',{attrs:{"show-search":!_vm.isWorkspacePage}}):(_vm.isEmpty && _vm.activeHeader && !_vm.isWorkspacePage)?_c('AppEmptyList',{staticClass:"pt-32",attrs:{"from":_vm.isLibraryCollections ? 'library-collections' : 'collections'}}):[(!_vm.isWorkspacePage)?_c('div',{staticClass:"library-page-container mb-12"},[_c('CollectionsSearch',_vm._b({staticClass:"mt-3",attrs:{"is-store-product-preload":_vm.isStoreProductPreload,"custom-search-keyword":_vm.isWorkspacePage ? (_vm.workspacePage.name || '') : ''}},'CollectionsSearch',_vm.propsDataForSearching,false)),(!_vm.searchProductsMode)?_vm._t("topDetails",null,{"actionResort":_vm.fetchCollections}):_vm._e()],2):_vm._e(),_c('v-col',{staticClass:"mt-sm-9 mb-12 mt-0",class:_vm.isListingView ? 'library-page-container' : 'page-container'},[_vm._t("headerEditor",null,null,{ searchProductsMode: _vm.searchProductsMode }),_c('CollapseSection',{attrs:{"id":"group","arr-sections":_vm.filteredListOfCollections,"enable-collapse":_vm.useSectionSettings,"func-is-show-section":_vm.isShowSection,"sections-order":_vm.sectionsOrder,"show-loading-header-func":_vm.isCollapseHeaderLoading,"size-icon":_vm.isListingView ? 34 : null},on:{"changeExpandSection":function($event){return _vm.$emit('changeExpandSection', $event)},"changeReorder":function($event){return _vm.$emit('changeReorder', $event)}},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var collection = ref.section;
return [_c('div',{staticClass:"d-flex justify-space-between",style:(_vm.isListingView ? 'font-size: 24px' : '')},[_c('div',[_vm._v(" "+_vm._s(collection.useAlternativeText ? collection.alternativeText : collection.name)+" "),(_vm.getSafe(collection.headerIcon, 'name'))?[_c('v-icon',_vm._b({attrs:{"size":!_vm.isListingView ? 18 : 24}},'v-icon',_vm.getProps(collection.headerIcon, ['name']),false),[_vm._v(" "+_vm._s(_vm.getSafe(collection.headerIcon, 'name'))+" ")])]:_vm._e()],2),(collection.showExpanded && _vm.filteredCollectionsList(collection.list).length > _vm.getExpandedAmount(collection))?_vm._t("expandedIcon",null,null,{ group: collection.group }):_vm._e()],2)]}},{key:"content",fn:function(ref){
var collection = ref.section;
return [(_vm.isLoadingRowData)?[(_vm.isListingView)?_c('ListingSkeleton',{attrs:{"items":4}}):_c('v-card',{staticClass:"py-6 pb-15 px-4 mb-10"},[_c('ScheduleSkeleton',{attrs:{"header-columns":4,"body-rows":4}})],1)]:_vm._e(),[(_vm.isListingView)?_c('CollectionListing',{attrs:{"collapsed-items-amount":_vm.getExpandedAmount(collection),"collections-list":_vm.collectionList(collection),"group":collection.group,"intersect":_vm.getCollectionsLimit_100 && collection.allowGetAllItems ? false : collection.intersect,"is-loading":_vm.isFetchingForGroup(collection.group) && (collection.expanded || collection.expanded === undefined),"is-shared-page":_vm.isSharedPage,"is-community-collections":_vm.isCommunityCollections,"show-collapsed-items":_vm.showCollapsedItems(collection),"show-counter":collection.showCounter},on:{"click-action-menu":function($event){return _vm.onClickActionMenu(Object.assign({}, $event,
                {collection: collection,
                group: collection.group,
                actionsConditions: collection.actionTypes,
                showActions: collection.actions,
                kind: _vm.activeHeader.kind}))},"onIntersect":function($event){return _vm.onIntersect(Object.assign({}, $event,
                {list: collection.list,
                collectionGroup: collection.group,
                pagination: collection.paginationState,
                allowGetAllItems: collection.allowGetAllItems}))}}}):_c('CollectionsTable',{attrs:{"collections-list":_vm.collectionList(collection),"intersect":_vm.getCollectionsLimit_100 && collection.allowGetAllItems ? false : collection.intersect,"group":collection.group,"hide-column-members":_vm.hideColumnMembers,"is-shared-page":_vm.isSharedPage},on:{"click-action-menu":function($event){return _vm.onClickActionMenu(Object.assign({}, $event,
                {collection: collection,
                group: collection.group,
                actionsConditions: collection.actionTypes,
                showActions: collection.actions,
                kind: _vm.activeHeader.kind}))},"onIntersect":function($event){return _vm.onIntersect(Object.assign({}, $event,
                {list: collection.list,
                collectionGroup: collection.group,
                pagination: collection.paginationState,
                allowGetAllItems: collection.allowGetAllItems}))}}})]]}}],null,true)})],2)],_vm._t("createButtonActions",null,null,{ searchProductsMode: _vm.searchProductsMode }),_c('ShareAdvancedDialog',{attrs:{"start-subscribe":true,"disable-actions":_vm.disableActionsSharing,"disabled":!(_vm.disableActionsSharing && !_vm.activeCollection.follow),"invite-to-text-type":_vm.COLLECTION,"item":_vm.activeCollection,"item-group":_vm.activeCollectionGroup},on:{"manageSharedUser":function($event){return _vm.manageSharedUser({
        variables: Object.assign({}, $event.variables,
          {collectionId: _vm.activeCollection.id}),
        criteria: $event.criteria,
      })}}}),_c('Actions',_vm._b({attrs:{"show-activator":false,"item":_vm.selectedItem,"position-x":_vm.positionX,"position-y":_vm.positionY,"is-community-collections":_vm.isCommunityCollections,"value":_vm.showActionsCard},on:{"update:item":function($event){_vm.selectedItem=$event},"update:value":function($event){_vm.showActionsCard=$event},"manageActions":_vm.manageActions}},'Actions',_vm.actionsProps,false)),_c('AppProgressLinear',{staticClass:"linear-block"}),(_vm.canUseQuickViewLink)?_c('div',{staticClass:"action-footer__wrapper action-footer__wrapper--wspage"},[_c('div',{staticClass:"action-footer__wrapper__items"},[_c('ShareLink',{attrs:{"is-page-link":""}})],1)]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }