<template>
  <component
    :is="enableCollapse ? 'v-expansion-panels' : 'div'"
    v-model="sections"
    flat
    readonly
    class="content"
    accordion
    multiple>
    <component
      :is="enableCollapse ? 'draggable' : 'div'"
      v-model="list"
      :set-data="setData"
      :disabled="showSpinner"
      handle=".header"
      filter=".icon-header"
      style="width: 100%"
      @choose="choose"
      @change="changeSort"
      @end="endDrop"
      @start="startDrag">
      <component
        :is="enableCollapse ? 'v-expansion-panel' : 'div'"
        v-for="(section, index) in list"
        :key="section.id"
        class="item"
        eager
        :data-test="section.dataTest">
        <template v-if="manageShowingSection(section)">
          <component
            :is="enableCollapse ? 'v-expansion-panel-header' : 'div'"
            v-if="section.name && !showLoadingHeaderFunc(section)"
            class="header"
            hide-actions
            :class="{'collapse-header':enableCollapse}">
            <v-icon
              v-if="enableCollapse"
              class="icon-header"
              :class="{'collapse-icon':section.expanded }"
              color="lightBlack"
              :size="sizeIcon"
              @click.stop="switcherPanel(index,section)">
              mdi-chevron-down
            </v-icon>
            <slot
              :section="section"
              name="header" />
          </component>
          <v-skeleton-loader
            v-else
            class="header ml-12 mb-6"
            :width="300"
            type="heading" />
          <component
            :is="enableCollapse ? 'v-expansion-panel-content' : 'div'"
            class="content">
            <slot
              :section="section"
              name="content" />
          </component>
        </template>
      </component>
    </component>
  </component>
</template>
<script>
import draggable from 'vuedraggable';
import {
  VExpansionPanels, VExpansionPanel,
  VExpansionPanelHeader,
  VExpansionPanelContent,
} from 'vuetify/lib';
import { mapState } from 'vuex';
import renderingItemsWithDataTest from '@/utils/renderingItemsWithDataTest';
export default {
  name: 'CollapseSection',
  components: {
    draggable,
    VExpansionPanel,
    VExpansionPanels,
    VExpansionPanelHeader,
    VExpansionPanelContent,
  },
  props: {
    id: {
      type: String,
      default: 'id',
    },
    sizeIcon: {
      type: Number,
      default: 26,
    },
    funcIsShowSection: {
      type: [Function, Boolean],
      default: () => false,
    },
    arrSections: {
      type: Array,
      required: true,
    },
    enableCollapse: {
      type: Boolean,
      default: false,
    },
    sectionsOrder: {
      type: Array,
      default: () => [],
    },
    showLoadingHeaderFunc: {
      type: Function,
      default: () => false,
    },
  },
  data() {
    return {
      list: [],
      sections: [],
      selectedEl: null,
      isPreClosed: false,
    };
  },
  computed: {
    ...mapState(['showSpinner']),
    listIdsAfterReorder() {
      return this.list.map(e => e[this.id]);
    },
    reorderExpandSections() {
      const { id } = this;
      return this.list?.filter(e => e.expanded).map(e => this.list.findIndex(q => q[id] === e[id]));
    },
    isEqualArrs() {
      const oldArr = this.list.map(e => ({
        expanded: e.expanded ?? false,
        id: e[this.id],
      }));
      const newArr = this.sectionsOrder.map(e => ({
        expanded: e.expanded, id: e.id,
      }));
      return this.lodash.isEqual(oldArr, newArr);
    },
  },
  watch: {
    sectionsOrder(arr) {
      this.sectionsOrderUpdated(arr);
    },
    reorderExpandSections(val) {
      if (this.enableCollapse) this.sections = val;
    },
  },
  created() {
    const newItems = renderingItemsWithDataTest({
      items: this.arrSections, arg: 'name', after: '_table',
    });
    this.list = newItems;

    // section order update
    this.sectionsOrderUpdated(this.sectionsOrder);
  },
  methods: {
    manageShowingSection(section) {
      const { funcIsShowSection: func } = this;
      if (typeof func !== 'function') return func;
      return func(section);
    },
    reuseCollapse(arr) {
      const { id } = this;
      this.list = this.list.map(e => ({
        ...e,
        expanded: arr.find(q => q.id === e[id])?.expanded ?? true,
      }));
    },
    reorderSection(ids) {
      const { id } = this;
      this.list.sort((a, b) => {
        return ids.indexOf(a[id]) - ids.indexOf(b[id]);
      });
    },
    sectionsOrderUpdated(arr) {
      if (this.enableCollapse && arr.length && !this.isEqualArrs) {
        const ids = arr.map(e => e.id);
        this.reorderSection(ids);
        this.reuseCollapse(arr);
      }
    },
    changeSort() {
      this.$emit('changeReorder', this.listIdsAfterReorder);
    },
    choose(ev) {
      this.selectedEl = ev.item.querySelector('.v-expansion-panel-header');
    },
    setData(dataTransfer) {
      dataTransfer.setDragImage(this.selectedEl, 10, 10);
    },
    endDrop(ev) {
      const index = ev.newIndex;
      if (this.isPreClosed) {
        this.open(index);
        this.isPreClosed = false;
      }
    },
    startDrag(ev) {
      const index = ev.oldIndex;
      if (this.list[index]?.expanded) {
        this.isPreClosed = true;
        this.close(index);
      }
    },
    open(index) {
      const section = this.list[index];
      this.$set(section, 'expanded', true);
    },
    close(index) {
      const section = this.list[index];
      this.$set(section, 'expanded', false);
    },
    switcherPanel(index) {
      if (this.showSpinner) return false;
      const item = this.list[index];
      const { expanded } = item || {
      };
      !expanded ? this.open(index) : this.close(index);
      this.$emit('changeExpandSection', this.list[index]);
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .v-icon.v-icon {
  transition: none;
}
::v-deep .v-expansion-panel--active > .v-expansion-panel-header {
  min-height: 48px;
}
::v-deep .v-expansion-panel--active:not(:first-child), .v-expansion-panel--active + .v-expansion-panel {
  margin-top: 0;
}
::v-deep .v-expansion-panel-content__wrap {
  padding: 0 !important;
}
::v-deep .v-expansion-panel-header {
  padding-bottom: 0;
  padding-top: 0;
}
::v-deep .v-expansion-panel {
  &:before {
    content: none;
  }
  &:not(:first-child):after {
    content: none;
  }
}
.v-item-group.v-expansion-panels {
  &,
  & .v-expansion-panel,
  & .v-expansion-panel-content {
    transition: none !important;
  }
}
.icon-header {
  flex: 0 !important;
}
</style>
