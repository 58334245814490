<template>
  <Collections
    is-library-collections
    :is-store-product-preload="isStoreProductPreload"
    :props-data-for-searching="propsDataForSearching"
    use-section-settings
    :sections-order="sectionsOrder"
    :filtered-list-of-collections="filteredListOfCollections"
    @changeReorder="$emit('changeReorder', $event)"
    @changeExpandSection="$emit('changeExpandSection', $event)">
    <template #topDetails="{actionResort}">
      <app-sort
        wrapper-class="col-12 px-0"
        :default-item="defaultItem"
        :sort-items="sortItems"
        @resort="actionResort" />
    </template>
    <template #headerEditor="{ searchProductsMode }">
      <Header
        v-if="!searchProductsMode && isLibraryToShow"
        :render-sharing-in-header="renderSharingInHeader"
        is-library
        :active-header="activeHeader" />
    </template>
  </Collections>
</template>
<script>
import Collections from '@/components/Collections';
import AppSort from '@/components/App/AppSort';
import propsMyCollections from '@/mixins/propsMyCollections';
export default {
  name: 'LibraryCollectionsWrapper',
  components: {
    AppSort,
    Collections,
    Header: () => import('@/components/Listing/CollectionHeader'),
  },
  mixins: [propsMyCollections],
  props: {
    renderSharingInHeader: {
      type: Boolean,
      default: true,
    },
    sectionsOrder: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
<style scoped>
</style>
