<template>
  <fragment>
    <v-skeleton-loader
      :height="32"
      type="text" />
    <v-skeleton-loader
      class="d-none d-md-flex mt-n3 w-100 gap-1"
      type="chip@6" />
  </fragment>
</template>

<script>
export default {
  name: 'CollectionSearchSkeleton',
};
</script>

<style scoped lang="scss">
  .v-skeleton-loader.v-skeleton-loader--is-loading {
    ::v-deep .v-skeleton-loader__text {
      height: 100%;
    }
  }
</style>