var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"collections"},[_c('div',{staticClass:"secondary-blocks"},[_c('ListingImageItem',_vm._g({attrs:{"libraries-list":_vm.updatedCollectionsList,"show-collapsed-items":_vm.showCollapsedItems,"collapsed-items-amount":_vm.collapsedItemsAmount,"intersect":_vm.intersect,"is-fetching":_vm.isLoading,"show-counter":_vm.showCounter,"show-unread-comments":_vm.group == _vm.WORKSPACE || _vm.group == _vm.GROUP_SHARED,"counter":{
        prop: 'productsCount',
        type: 'products',
      }},on:{"navigate":function($event){return _vm.navigateNext($event, _vm.group, _vm.isSharedPage)}},scopedSlots:_vm._u([_vm._l((_vm.$scopedSlots),function(_,name){return {key:name,fn:function(props){return [_vm._t(name,null,null,props)]}}}),{key:"actions",fn:function(ref){
      var item = ref.item;
return [(!_vm.hideActions && !_vm.isSharedPage)?_c('v-icon',_vm._g({staticClass:"brightness-icon",attrs:{"color":"lightGrey","medium":""},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('click-action-menu', {
            item: item,
            event: $event,
          })}}},_vm.$listeners),[_vm._v(" mdi-dots-vertical ")]):_vm._e()]}}],null,true)},_vm.$listeners))],1)])}
var staticRenderFns = []

export { render, staticRenderFns }