<template>
  <div class="workspace-page__main-header">
    <div class="workspace-page__header flex-sm-row flex-column">
      <LogoItems
        :pictures="setPictures(workspacePage)"
        :collection="workspacePage" />
      <div class="d-flex flex-column">
        <div class="workspace-page__title-and-desc">
          <div class="workspace-page__header-short-name">
            {{ workspacePage.name }}
          </div>
          <div
            v-safe-html="workspaceDescription"
            class="workspace-page__title-and-desc__description mb-4 mb-sm-0" />
        </div>
        <fragment v-if="useMakePageFollowable && !hideFollow">
          <div
            v-if="!workspaceSelectorOnPages"
            class="workspace-page__actions">
            <app-action-btn
              class="nav-card-action workspace-page__actions-follow"
              :access="canFollowPage"
              :disabled="isLoadingFollow"
              show-tooltip
              bottom
              @click="onFollowActions">
              <span class="collection-action-button__header-span">
                {{ isFollowedPage ? 'Unfollow' : 'Follow' }}
              </span>
            </app-action-btn>
          </div>
          <div
            v-else
            class="workspace-page__actions">
            <PageFollowWithWsSelection
              class="workspace-page__actions-follow__ws-selection"
              :show-icon="false"
              :item="workspacePage" />
            <SuggestWorkspacePageDialog
              v-if="showUpdatesToWorkspaceGuests"
              class="workspace-page__actions-suggest-followers"
              :workspace-page-id="workspacePage.id"
              :page-workspace-id="workspacePage.workspaceId" />
          </div>
        </fragment>
      </div>
    </div>
  </div>
</template>
<script>
import LogoItems from '@/components/Listing/ListingImageItem/ListingImage';
import SetPictures from '@/mixins/SetPictures';
import PageFollowWithWsSelection from '@/components/Collections/CollectionsActions/CollectionsActionsComponent/PageFollow/PageFollowWithWsSelection';
import SuggestWorkspacePageDialog from '@/components/App/AppSuggestDialog/SuggestWorkspacePageDialog';
import {
  mapGetters, mapActions, mapState,
} from 'vuex';
export default {
  name: 'WorkspacePageHeader',
  components: {
    LogoItems,
    PageFollowWithWsSelection,
    SuggestWorkspacePageDialog,
  },
  mixins: [SetPictures],
  props: {
    workspacePage: {
      type: Object,
      default: null,
      required: true,
    },
    hideFollow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoadingFollow: false,
    };
  },
  computed: {
    ...mapGetters({
      getIdsOfMyPages: 'MyPages/getIdsOfMyPages',
      useMakePageFollowable: 'FeatureFlags/useMakePageFollowable',
      workspaceSelectorOnPages: 'FeatureFlags/workspaceSelectorOnPages',
      showUpdatesToWorkspaceGuests: 'FeatureFlags/showUpdatesToWorkspaceGuests',
    }),
    ...mapState(['role']),
    ...mapGetters('Workspace', ['getActiveWorkspaceRole']),
    ...mapGetters('UserRoles', ['canFollowPage']),
    isFollowedPage() {
      return this.getIdsOfMyPages.includes(this.workspacePage.id);
    },
    workspaceDescription() {
      const { description } = this.workspacePage ?? {
      };
      if (!description) return '';
      return description;
    },
  },
  methods: {
    ...mapActions('MyPages', ['followPage', 'unfollowPage']),
    async onFollowActions() {
      const { allowed = false } = this.canFollowPage ?? {
      };
      if (!allowed) {
        return;
      }
      const {
        id: pageId = '',
        workspaceId: pageWorkspaceId = '',
      } = this.workspacePage ?? {
      };
      const params = {
        pageId,
        pageWorkspaceId,
      };
      this.isLoadingFollow = true;
      if (this.isFollowedPage) {
        await this.unfollowPage(params);
      } else {
        await this.followPage(params);
      }
      this.isLoadingFollow = false;
    },
  },
};
</script>
<style scoped lang="scss">
  .workspace-page {
    &__main-header {
      display: grid;
      padding: 0 30px;
      left: 0;
      width: 100%;
      position: sticky;
    }
    &__title-and-desc {
      max-width: 600px;
      &__description {
        word-break: break-word;
        white-space: pre-wrap;
      }
    }
  }
  .workspace-page {
    &__header {
      margin-top: 10px;
      justify-self: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      gap: 30px;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: flex-start;
      &-short-name {
        font-weight: bold;
        font-size: 36px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
      }
    }
  }
  .workspace-page__actions {
    display: flex;
    align-items: center;
    column-gap: 15px;
  }
  .workspace-page__actions-suggest-followers {
    margin-top: 20px;
    cursor: pointer;
  }
  .workspace-page__actions-follow {
    padding: 0 24px !important;
    width: 100%;
    max-width: 130px;
    margin-top: 20px;
    &__ws-selection {
      width: max-content;
      cursor: pointer;
      margin-top: 20px;
    }
    ::v-deep {
      .c-grey {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 0 !important;
        height: 100%;
      }
    }
  }
  .collection-action-button__header-span {
    height: 40px !important;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
