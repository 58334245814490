<template>
  <app-kebab-actions
    :value="menu"
    v-bind="$attrs"
    @showMenu="onShowMenu"
    v-on="$listeners">
    <div
      v-for="component in actionsConditions"
      :key="component.id"
      style="padding: 0px"
      :style="component.kind.includes(kind) ? '' : 'display: none'">
      <component
        :is="component.name"
        v-if="component.kind.includes(kind) && value"
        :id="item.id"
        is-icon
        :disabled="component.disabled"
        :is-library="isLibrary"
        view-in-actions
        :is-listing="true"
        :status-menu="menu"
        :members="item.members"
        :item="item"
        :invite-to-text-type="COLLECTION"
        :library-id="libraryId"
        :collection-id="item.id"
        :libraries-list="librariesList"
        :is-community-collections="isCommunityCollections"
        :group="group"
        @showInfo="showInfo"
        @manageActions="$emit('manageActions', { action: $event, item })"
        @manageSharedUser="manageSharedUser({
          variables: {
            ...$event.variables,
            collectionId: item.id,
          },
          criteria: $event.criteria,
        })" />
    </div>
  </app-kebab-actions>
</template>
<script>
import { COLLECTION } from '@/constants/cores';
import {
  mapActions,
  mapMutations,
  mapState,
} from 'vuex';
import AppKebabActions from '@/components/App/AppKebabActions';
export default {
  name: 'CollectionsActions',
  components: {
    CollectionShareDialog: () => import('@/components/CollectionsLibrarysRelocate/ShareDialog'),
    ShareAdvancedDialog: () => import('@/components/Collections/CollectionBtnSharingOption'),
    CollectionDelete: () => import('./CollectionsActionsComponent/CollectionDelete'),
    CollectionCopy: () => import('./CollectionsActionsComponent/CollectionCopy'),
    CollectionAddToCustomLibrary: () => import('./CollectionsActionsComponent/CollectionAddToCustomLibrary'),
    CollectionFollow: () => import('./CollectionsActionsComponent/CollectionFollow'),
    CollectionInfo: () => import('./CollectionsActionsComponent/CollectionInfo'),
    CollectionCustomRemove: () => import('./CollectionsActionsComponent/CollectionCustomRemove'),
    SuggestionIgnore: () => import('./CollectionsActionsComponent/SuggestionIgnore'),
    SuggestCollectionDialog: () => import('@/components/App/AppSuggestDialog/SuggestCollectionDialog'),
    PageFollow: () => import('./CollectionsActionsComponent/PageFollow'),
    AppKebabActions,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    isCommunityCollections: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: null,
    },
    group: {
      type: String,
      default: null,
    },
    kind: {
      type: String,
      default: () => '',
    },
    actionsConditions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      COLLECTION,
      menu: false,
    };
  },
  computed: {
    ...mapState('Libraries', ['librariesList']),
    ...mapState(['activeHeader']),
    isCustomLibrary() {
      return this.item?.kind == 'custom';
    },
    isLibrary() {
      return this.activeHeader?.docType === 'library';
    },
    libraryId() {
      return this.isLibrary ? this.activeHeader.id : this.$route.params.id;
    },
  },
  watch: {
    value: {
      handler(val) {
        this.onShowMenu(val);
      },
      immediate: true,
    },
    menu(val) {
      if (val) {
        const { item } = this;
        const { id } = item;
        this.setActiveCollection(item);
        this.getCollectionSharingOptions({
          id,
        });
      }
    },
  },
  methods: {
    ...mapMutations('Collections', ['setActiveCollection']),
    ...mapActions({
      manageSharedUser: 'Collections/manageSharedUser',
      getCollectionSharingOptions: 'Collections/getCollectionSharingOptions',
      showCollectionInfo: 'Collections/showCollectionInfo',
    }),
    async onShowMenu(val) {
      this.menu = val;
      this.$emit('update:value', val);
      if (!val) {
        this.$emit('update:item', null);
      }
    },
    async showInfo() {
      await this.showCollectionInfo({
        collectionId: this.item.id,
        isCommunityCollections: this.isCommunityCollections,
      });
    },
  },
};
</script>
<style scoped lang="scss">
</style>
